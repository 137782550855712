import React, { useState } from "react"
import { Global } from "@emotion/core"
import { globalStyles } from "../styles"
import IntroHero from "../components/introHero"
import { Part1, Part2, Part3, Part4, Part5 } from "../components/product"
import Register from "../components/register"
import Footer from "../components/footer"
import MobileNavigation from "../components/navigationMobile"
import SEO from "../components/seo"
import News from "../components/newsBar"

interface Props {
  location: any
}

const Home: React.FC<Props> = ({ location }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Global styles={globalStyles} />
      <SEO />
      <News />
      <MobileNavigation isOpen={isOpen} setOpen={setOpen} location={location} />
      <IntroHero location={location} />
      <main>
        <Part1 />
        <Part2 />
        <Part3 />
        <Part4 />
        <Part5 />
        <Register />
        <Footer />
      </main>
    </>
  )
}

export default Home
