import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Section from "../../section"
import SectionTitle from "../../sectionTitle"
import Layout from "../../layout"
import styled from "@emotion/styled"
import MovingList from "../../movingList"
import Testimonials from "../../testimionials"
import IconAndText from "../../iconAndText"

import mediaqueries from "../../../styles/media"

const siteQuery = graphql`
  query part5Query {
    mdx(frontmatter: { gatsbyTitle: { eq: "product-part5" } }) {
      id
      body
      frontmatter {
        title
        description
        companies
        testimonialOn
        testimonials {
          company
          text
        }
        icons {
          icon {
            id
            publicURL
          }
          text
        }
      }
    }
  }
`
const Part5 = () => {
  const data = useStaticQuery(siteQuery)

  const {
    title,
    description,
    testimonials,
    testimonialOn,
    companies,
    icons,
  } = data.mdx.frontmatter
  return (
    <BodyHolder>
      <Layout>
        <Section>
          <SectionTitle
            number={5}
            text={title}
            description={description}
            marginLeft="120px"
          />
          <SubSection gridString="1fr" marginTop="50px">
            <MovingList list={companies} />
          </SubSection>
          {testimonialOn && (
            <SubSection gridString="1fr 1fr" marginTop="0px">
              {testimonials.map((item: { company: string; text: string }) => (
                <Testimonials
                  key={item.company}
                  company={item.company}
                  text={item.text}
                />
              ))}
            </SubSection>
          )}
          <SubSection gridString="1fr 1fr" marginTop="0px">
            {icons.map(
              (item: { icon: { publicURL: string }; text: string }) => (
                <IconAndText
                  key={item.text}
                  iconUrl={item.icon.publicURL}
                  text={item.text}
                />
              )
            )}
          </SubSection>
        </Section>
      </Layout>
    </BodyHolder>
  )
}

export default Part5

const BodyHolder = styled.section`
  background: ${(p: any) => p.theme.colors.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
`
const SubSection = styled.div`
  display: grid;
  grid-template-columns: ${(p: any) => p.gridString};
  margin-top: ${(p: any) => p.marginTop};

  ${mediaqueries.desktop`
  grid-template-columns: 1fr;
  padding: 10px;
`};
`
