import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../section"
import SectionTitle from "../../sectionTitle"
import Layout from "../../layout"
import styled from "@emotion/styled"
import FirstWordColorText from "../../firstWordColorText"
import OrangeBox from "../../orangeBox"
import Mdx from "../../mdx"
import smallLine from "./smallLine.svg"
import bigLine from "./bigLine.svg"
import mediaqueries from "../../../styles/media"
import Lightbox from "../../lightbox"

const siteQuery = graphql`
  query part2Query {
    mdx(frontmatter: { gatsbyTitle: { eq: "product-part2" } }) {
      id
      body
      frontmatter {
        title
        text1
        text2
        text3
        orangeBox1
        orangeBox2
        image {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
const Part2 = () => {
  const data = useStaticQuery(siteQuery)

  const {
    title,
    text1,
    text2,
    text3,
    orangeBox1,
    orangeBox2,
    image,
    image2,
    image3,
    image4,
  } = data.mdx.frontmatter

  const { body } = data.mdx

  return (
    <BodyHolder>
      <Layout>
        <Section>
          <SectionTitle number={2} text={title} />
          <SubSection gridString="1fr 2fr">
            <DefinitionHolder>
              <FirstWordColorText text={text1} />
            </DefinitionHolder>
            <Lightbox src={image.childImageSharp.fluid.originalImg}>
              <Img alt={text1} fluid={image.childImageSharp.fluid} />
            </Lightbox>
          </SubSection>
          <SubSection gridString="1fr 2fr">
            <DefinitionHolder>
              <FirstWordColorText text={text2} />
            </DefinitionHolder>
            <Lightbox src={image2.childImageSharp.fluid.originalImg}>
              <Img alt={text2} fluid={image2.childImageSharp.fluid} />
            </Lightbox>
          </SubSection>
          <LinesHolder>
            <ImgForLine
              src={bigLine}
              alt={orangeBox2}
              zIndex="5"
              top="310px"
              right="75.5%"
            />
            <SubSection gridString="1fr 1fr">
              <OrangeBox text={orangeBox1} justifySelf="flex-end" />
              <OrangeBox text={orangeBox2} justifySelf="flex-start" />
            </SubSection>
            <ShadowBox marginTop="50px" zIndex="2">
              <Lightbox src={image3.childImageSharp.fluid.originalImg}>
                <Img alt={orangeBox1} fluid={image3.childImageSharp.fluid} />
              </Lightbox>
            </ShadowBox>
            <ImgForLine
              src={smallLine}
              alt={orangeBox1}
              zIndex="5"
              top="310px"
              left="73%"
            />
          </LinesHolder>
          <SubSection gridString="1fr 1fr">
            <Mdx content={body} />
          </SubSection>
          <SubSection gridString="1fr 1fr">
            <ShadowBox marginLeft="100px">
              <Lightbox src={image4.childImageSharp.fluid.originalImg}>
                <Img alt={orangeBox1} fluid={image4.childImageSharp.fluid} />
              </Lightbox>
            </ShadowBox>
            <NotificationHolder>
              <FirstWordColorText text={text3} />
            </NotificationHolder>
          </SubSection>
        </Section>
      </Layout>
    </BodyHolder>
  )
}

export default Part2

const BodyHolder = styled.section`
  background: ${(p: any) => p.theme.colors.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
`
const SubSection = styled.div`
  display: grid;
  grid-template-columns: ${(p: any) => p.gridString};
  margin-top: 100px;

  ${mediaqueries.desktop`
  grid-template-columns: 1fr;
  margin-top: 50px;
`};
`

const DefinitionHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-right: 80px;

  ${mediaqueries.desktop`
  margin-bottom: 50px;
`};
`
const NotificationHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 80px;

  ${mediaqueries.desktop`
  padding: 50px 10px;
`};
`

const LinesHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const ShadowBox = styled.div`
  margin-top: ${p => p.marginTop};
  margin-left: ${p => p.marginLeft};
  border-radius: ${p => p.theme.radius.normal};
  filter: ${(p: any) => p.theme.shadow.box};
  overflow: hidden;
  z-index: ${(p: any) => p.zIndex};

  ${mediaqueries.desktop`
  margin: 0;
`};
`

const ImgForLine = styled.img`
  position: absolute;
  z-index: ${(p: any) => p.zIndex};
  top: ${(p: any) => p.top};
  left: ${(p: any) => p.left};
  right: ${(p: any) => p.right};

  ${mediaqueries.desktop`
  display: none;
`};
`
