import React from "react"
import styled from "@emotion/styled"

import mediaqueries from "../../styles/media"
import { getTheLastWord, removeTheLastWord } from "../../utils/"

interface Props {
  text: string
  number: number
  description?: string
  marginLeft?: string
}

interface ThemeProps {
  theme: any
  marginLeft?: string
}

const SectionTitle: React.FC<Props> = ({
  text,
  number,
  description,
  marginLeft = "100px",
}) => {
  const part2 = getTheLastWord(text)
  const part1 = removeTheLastWord(text)

  return (
    <Section>
      <SectionHolder>
        <Number>{number}</Number>
        <Title>
          {`${part1} `}
          <Span>{part2}</Span>
        </Title>
      </SectionHolder>
      {description && (
        <Description marginLeft={marginLeft}>{description}</Description>
      )}
    </Section>
  )
}

export default SectionTitle

const Section = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaqueries.desktop`
  margin-top: 50px;
`}
`

const SectionHolder = styled.div`
  display: flex;
  width: 100%;
  min-height: 130px;
  align-items: center;
`

const Number = styled.div`
  font-family: ${(p: ThemeProps) => p.theme.fonts.numberFont};
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 197.18%;
  /* or 189px */
  letter-spacing: 0.015em;
  margin-right: 58px;
  color: ${(p: ThemeProps) => p.theme.colors.secondary};

  ${mediaqueries.desktop`
font-size: 64px;
margin-right: 20px;
`};
`

const Title = styled.h2`
  font-family: ${(p: ThemeProps) => p.theme.fonts.exo};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 197.18%;
  /* or 59px */
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: ${(p: ThemeProps) => p.theme.colors.primary};

  ${mediaqueries.desktop`
    margin-top: 10px;
    line-height: 1.2;
    font-size: 24px;
`};
`

const Description = styled.h3`
  font-family: ${(p: ThemeProps) => p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 197.18%;
  /* or 35px */
  letter-spacing: 0.015em;
  margin-left: ${(p: ThemeProps) => p.marginLeft};
  margin-top: -50px;
  color: ${(p: ThemeProps) => p.theme.colors.primary};
  max-width: 730px;

  ${mediaqueries.desktop`
    margin-top: 0px;
    margin-left: 0px;
`};
`

const Span = styled.span`
  color: ${(p: ThemeProps) => p.theme.colors.secondary};
`
