import React from "react"
import styled from "@emotion/styled"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { keyframes } from "@emotion/core"

import mediaqueries from "../../styles/media"

interface Props {
  list: string[]
}

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  rtl: true,
}

const getShortCompany = (text: string): string => {
  const offSet = 45
  if (text.length > offSet) {
    return `${text.substring(0, offSet)}...`
  }
  return text
}

const MovingList: React.FC<Props> = ({ list }) => {
  return (
    <ListHolder>
      <Slider {...settings}>
        {list.map((item: string) => (
          <FlipItem key={item}>
            <p>{getShortCompany(item)}</p>
          </FlipItem>
        ))}
      </Slider>
    </ListHolder>
  )
}

export default MovingList

const ListHolder = styled.div`
  display: block;
  width: 100%;
  height: 100px;
  overflow: hidden;
  text-align: center;
  max-width: 1160px;
  margin: auto;

  ${mediaqueries.desktop`
   width: 100%;
   height: 150px;
   font-size: 18px;
   z-index: 0;

`};
`

const FlipItem = styled.div`
  color: ${(p: any) => p.theme.colors.background};
  background-color: ${(p: any) => p.theme.colors.secondary};
  padding: 10px 12px;
  height: 50px;
  border-radius: ${(p: any) => p.theme.radius.normal};
  display: flex;
  width: 100%;
  margin: 25px 0;
  font-family: ${(p: any) => p.theme.fonts.exo};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 122.19%;
  text-align: center;
  letter-spacing: 0.05em;

  p {
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${mediaqueries.desktop`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin: 25px 0;
    font-size: 18px;
`};
`
