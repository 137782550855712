import React from "react"
import styled from "@emotion/styled"

interface Props {
  iconUrl: string
  text: string
}

const IconAndText: React.FC<Props> = ({ iconUrl, text }) => {
  return (
    <Holder>
      <img src={iconUrl} alt={text} />
      <IconText>{text}</IconText>
    </Holder>
  )
}

export default IconAndText

const Holder = styled.div`
  display: flex;
  width: 100%;
  max-width: 460px;
  min-height: 200px;
  align-items: center;
  justify-content: space-evenly;
  justify-self: center;
`

const IconText = styled.p`
  margin-left: 50px;
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 122.19%;
  /* or 22px */
  letter-spacing: 0.05em;
  color: ${(p: any) => p.theme.colors.primary};
  align-self: center;
`
