import React from "react"
import styled from "@emotion/styled"

const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export default Section
