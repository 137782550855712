import React from "react"
import styled from "@emotion/styled"
import { getFirstWord, removeTheFirstWord } from "../../utils/utils"

interface Props {
  text: string
}

const FirstWordColorText: React.FC<Props> = ({ text }) => {
  const firstWord = getFirstWord(text)
  const rest = removeTheFirstWord(text)

  return (
    <Text>
      <FirstWord>{firstWord} </FirstWord>
      {rest}
    </Text>
  )
}

export default FirstWordColorText

const Text = styled.p`
  font-style: normal;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.015em;
  margin: auto;
  height: 100%;
`

const FirstWord = styled.span`
  font-style: normal;
  font-size: 18px;
  font-weight: bold;
  color: ${(p: any) => p.theme.colors.secondary};
  line-height: 35px;
  letter-spacing: 0.015em;
  margin: auto;
`
