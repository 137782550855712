import React from "react"
import styled from "@emotion/styled"

interface Props {
  company: string
  text: string
}

const Testimonial: React.FC<Props> = ({ company, text }) => {
  return (
    <Holder>
      <TestmonialText>{text}</TestmonialText>
      <CompanyTitle>{company}</CompanyTitle>
    </Holder>
  )
}

export default Testimonial

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  min-height: 300px;
  justify-content: space-evenly;
  justify-self: center;
`

const TestmonialText = styled.q`
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  line-height: 122.19%;
  /* or 22px */
  letter-spacing: 0.05em;
  color: ${(p: any) => p.theme.colors.primary};
  align-self: center;
`
const CompanyTitle = styled.p`
  align-self: flex-end;
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 122.19%;
  /* or 22px */
  text-align: right;
  letter-spacing: 0.05em;
  color: ${(p: any) => p.theme.colors.primary};
`
