import React, { useState } from "react"
import styled from "@emotion/styled"
import mediaqueries from "../../styles/media"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import zoom from "./zoom.svg"

interface Props {
  src: string
  alt: string
  children: React.ReactNode
}

const LightboxWrapper = ({ children, src }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Holder onClick={() => setOpen(true)}>
      {children}
      <Button>
        <img src={zoom} alt="powiększ" /> powiększ
      </Button>
      {isOpen && (
        <Lightbox mainSrc={src} onCloseRequest={() => setOpen(false)} />
      )}
    </Holder>
  )
}

export default LightboxWrapper

const Holder = styled.div`
  display: block;
  text-align: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`

const Button = styled.button`
  display: none;

  @media (max-width: 66.875em) {
    display: inline-block;
    font-family: ${(p: any) => p.theme.fonts.sanSerif};
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 197.18%;
    /* identical to box height, or 22px */
    letter-spacing: 0.015em;
    color: ${(p: any) => p.theme.colors.primary};
  }
`
