import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Mdx from "../mdx"
import cancel from "./cancel.svg"
import styled from "@emotion/styled"
import mediaqueries from "../../styles/media"

const siteQuery = graphql`
  query newsQuery {
    mdx(frontmatter: { gatsbyTitle: { eq: "News" } }) {
      id
      body
      frontmatter {
        active
      }
    }
  }
`

const NewsBar = () => {
  const data = useStaticQuery(siteQuery)
  const { body } = data.mdx
  const { active } = data.mdx.frontmatter

  const [isShowing, setShowing] = useState(true)

  return (
    active &&
    isShowing && (
      <NewsHolder>
        <Mdx content={body} />
        <CloseButton onClick={() => setShowing(false)}>
          <img src={cancel} alt="zamknij news bar" />
        </CloseButton>
      </NewsHolder>
    )
  )
}

export default NewsBar

const NewsHolder = styled.div`
  display: flex;
  width: 100%;
  min-height: 50px;
  padding: 10px;
  background: ${(p: any) => p.theme.colors.topBarBackground};

  div {
    width: 95%;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  b,
  a,
  li,
  span {
    color: ${(p: any) => p.theme.colors.white};
    font-family: ${(p: any) => p.theme.fonts.exo};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    width: 95%;

    ${mediaqueries.desktop`
    font-size: 14px;
  `};
  }
`

const CloseButton = styled.button`
  display: block;
  text-align: center;
  min-width: 30px;
`
