import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../layout"
import Navigation from "../navigation"

import MDX from "../mdx"
import Button from "../button"
import FirstWordColorText from "../firstWordColorText"
import Img from "gatsby-image"
import mediaqueries from "../../styles/media"

interface Props {
  location?: any
  theme?: any
}

const siteQuery = graphql`
  query introQuery {
    mdx(frontmatter: { gatsbyTitle: { eq: "intro" } }) {
      id
      frontmatter {
        title
        underlineButtonLink
        underlineButtonText
        yellowButtonLink
        yellowButtonText
        description
        logo {
          id
          publicURL
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 1000
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      body
    }
  }
`

const IntroHero: React.FC<Props> = ({ location }) => {
  const data = useStaticQuery(siteQuery)
  const {
    title,
    underlineButtonLink,
    underlineButtonText,
    yellowButtonLink,
    yellowButtonText,
    description,
    image,
    logo,
  } = data.mdx.frontmatter

  return (
    <>
      <IntroHolder>
        <Layout>
          <Navigation location={location} />
          <MDX content={data.mdx.body}></MDX>
          <ButtonsHolder>
            <Button
              text={yellowButtonText}
              external={false}
              link={yellowButtonLink}
              type="yellow"
            />
            <Button
              text={underlineButtonText}
              external={false}
              link={underlineButtonLink}
              type="underline"
            />
          </ButtonsHolder>
          <HeroHolder>
            <Img fluid={image.childImageSharp.fluid} alt={description} />
          </HeroHolder>
          <WhiteCircle />
        </Layout>
      </IntroHolder>
      <BodyHolder>
        <Layout>
          <DescriptionHolder>
            <img src={logo.publicURL} alt="OpalCBox" width="200px" />
            <FirstWordColorText text={description} />
          </DescriptionHolder>
        </Layout>
      </BodyHolder>
    </>
  )
}

const IntroHolder = styled.header`
  height: 90vh;
  background: ${(p: any) => p.theme.colors.gradient};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: ${(p: any) => (p.isOpen ? "99" : "0")};

  ${mediaqueries.desktop`
  height: 100vh;
  padding-top: 150px;
`};
`

const ButtonsHolder = styled.div`
  margin-top: 140px;
  height: 51px;
  display: flex;
  z-index: 9990;

  ${mediaqueries.desktop`
  flex-direction: column;
  margin-top: 50px;
`};
`

const HeroHolder = styled.div`
  position: absolute;
  width: 538px;
  bottom: 75px;
  right: 30px;
  z-index: 1;

  ${mediaqueries.desktop`
  bottom: -4vh;
  right: -33px;
  z-index: 1;
  max-width: 90vw;
`};
`

const WhiteCircle = styled.div`
  position: absolute;
  bottom: 190px;
  right: 84px;
  width: 360px;
  height: 360px;
  border-radius: 100%;
  background: ${(p: Props) => p.theme.colors.background};
  z-index: 0;

  ${mediaqueries.desktop`
  bottom: 40px;
  right: 10px;
  width: 50vw;
  height: 50vw;
`};
`

const BodyHolder = styled.section`
  background: ${(p: Props) => p.theme.colors.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
`

const DescriptionHolder = styled.div`
  min-height: 250px;
  margin-top: -200px;
  filter: ${(p: Props) => p.theme.shadow.box};
  display: flex;
  justify-content: space-around;
  align-items: "center";
  z-index: 3;
  padding: 50px;
  position: relative;
  border-radius: ${(p: Props) => p.theme.radius.normal};
  background: ${(p: Props) => p.theme.colors.background};

  ${mediaqueries.desktop`
  flex-direction: column;
  padding: 50px 30px 75px;
  margin-top: -20px;
  & img {
    margin: auto;
  }
`};
`

export default IntroHero
