import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../section"
import SectionTitle from "../../sectionTitle"
import Layout from "../../layout"
import styled from "@emotion/styled"
import FirstWordColorText from "../../firstWordColorText"
import OrangeBox from "../../orangeBox"
import smallLine from "./smallLine.svg"
import bigLine from "./bigLine.svg"
import Lightbox from "../../lightbox"

import mediaqueries from "../../../styles/media"

const siteQuery = graphql`
  query part1Query {
    mdx(frontmatter: { gatsbyTitle: { eq: "product-part1" } }) {
      id
      frontmatter {
        title
        description
        definition
        orangeBox1
        orangeBox2
        image {
          id
          publicURL
        }
        image2 {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        image3 {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
const Part1 = () => {
  const data = useStaticQuery(siteQuery)

  const {
    title,
    description,
    definition,
    orangeBox1,
    orangeBox2,
    image,
    image2,
    image3,
  } = data.mdx.frontmatter

  return (
    <BodyHolder>
      <Layout>
        <Section>
          <SectionTitle number={1} text={title} description={description} />
          <SubSection>
            <DefinitionHolder>
              <FirstWordColorText text={definition} />
            </DefinitionHolder>
            <img alt={definition} src={image.publicURL} />
          </SubSection>
          <LinesHolder>
            <ImgForLine
              src={bigLine}
              alt={orangeBox2}
              zIndex="1"
              top="300px"
              right="47.5%"
            />
            <SubSection>
              <OrangeBox text={orangeBox1} justifySelf="flex-end" />
              <OrangeBox text={orangeBox2} justifySelf="flex-start" />
            </SubSection>
            <ShadowBox marginTop="60px" zIndex="2">
              <Lightbox src={image3.childImageSharp.fluid.originalImg}>
                <Img alt={orangeBox1} fluid={image2.childImageSharp.fluid} />
              </Lightbox>
            </ShadowBox>
            <ShadowBox marginTop="60px" zIndex="0">
              <Lightbox src={image3.childImageSharp.fluid.originalImg}>
                <Img alt={orangeBox1} fluid={image3.childImageSharp.fluid} />
              </Lightbox>
            </ShadowBox>
            <ImgForLine
              src={smallLine}
              alt={orangeBox1}
              zIndex="5"
              top="300px"
              left="43%"
            />
          </LinesHolder>
        </Section>
      </Layout>
    </BodyHolder>
  )
}

export default Part1

const BodyHolder = styled.section`
  background: ${(p: any) => p.theme.colors.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
`
const SubSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 100px;

  img {
    max-width: 100%;
  }

  ${mediaqueries.desktop`
  grid-template-columns: 1fr;
  margin-top: 10px;
`};
`

const DefinitionHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 100px;
  padding-right: 244px;

  ${mediaqueries.desktop`
  padding: 50px 0px;
`};
`

const LinesHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const ShadowBox = styled.div`
  margin-top: ${p => p.marginTop};
  border-radius: ${p => p.theme.radius.normal};
  filter: ${(p: any) => p.theme.shadow.box};
  overflow: hidden;
  z-index: ${(p: any) => p.zIndex};

  ${mediaqueries.desktop`
  margin-top: 50px;
`}
`

const ImgForLine = styled.img`
  position: absolute;
  z-index: ${(p: any) => p.zIndex};
  top: ${(p: any) => p.top};
  left: ${(p: any) => p.left};
  right: ${(p: any) => p.right};

  ${mediaqueries.desktop`
  display: none;
`};
`
