import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../section"
import SectionTitle from "../../sectionTitle"
import Layout from "../../layout"
import styled from "@emotion/styled"
import OrangeBox from "../../orangeBox"
import line from "./line.svg"
import Lightbox from "../../lightbox"

import mediaqueries from "../../../styles/media"

const siteQuery = graphql`
  query part4Query {
    mdx(frontmatter: { gatsbyTitle: { eq: "product-part4" } }) {
      id
      body
      frontmatter {
        title
        text1
        text2
        orangeBox
        image {
          id
          publicURL
        }
        image2 {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
const Part4 = () => {
  const data = useStaticQuery(siteQuery)

  const {
    title,
    text1,
    text2,
    image,
    image2,
    image3,
    orangeBox,
  } = data.mdx.frontmatter

  const { body } = data.mdx

  return (
    <BodyHolder>
      <Layout>
        <Section>
          <SectionTitle number={4} text={title} />
          <SubSection gridString="1fr 8fr 2fr" marginTop="50px">
            <div />
            <BoldHolder maxWidth="320px" justifySelf="flex-start">
              {text1}
            </BoldHolder>
          </SubSection>
          <SubSection gridString="1fr 1fr" marginTop="0px">
            <div />
            <StyledImage alt={text1} src={image.publicURL} />
          </SubSection>
          <LinesHolder>
            <ImgForLine
              src={line}
              alt={orangeBox}
              zIndex="1"
              top="20px"
              left="11%"
            />
            <SubSection gridString="1fr 5fr 8fr" marginTop="-200px">
              <div />
              <OrangeBox text={orangeBox} />
              <div />
            </SubSection>
            <SubSection gridString="1fr 10fr" marginTop="100px">
              <div />
              <Lightbox src={image2.childImageSharp.fluid.originalImg}>
                <Img alt={orangeBox} fluid={image2.childImageSharp.fluid} />
              </Lightbox>
            </SubSection>
          </LinesHolder>
          <SubSection gridString="1fr 1fr" marginTop="100px">
            <BoldHolder maxWidth="320px" justifySelf="center">
              {text2}
            </BoldHolder>
            <Lightbox src={image3.childImageSharp.fluid.originalImg}>
              <Img alt={text2} fluid={image3.childImageSharp.fluid} />
            </Lightbox>
          </SubSection>
        </Section>
      </Layout>
    </BodyHolder>
  )
}

export default Part4

const BodyHolder = styled.section`
  background: ${(p: any) => p.theme.colors.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;

  ${mediaqueries.desktop`
  margin: 50px auto;
  max-width: 100%;
`};
`
const SubSection = styled.div`
  display: grid;
  grid-template-columns: ${(p: any) => p.gridString};
  margin-top: ${(p: any) => p.marginTop};

  img {
    max-width: 100%;
  }

  ${mediaqueries.desktop`
  grid-template-columns: 1fr;
  margin: 25px 0;
`};
`
const BoldHolder = styled.div`
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 197.18%;
  /* or 35px */
  letter-spacing: 0.015em;
  justify-self: ${(p: any) => p.justifySelf};
  max-width: ${(p: any) => p.maxWidth};
  align-self: center;
  ${mediaqueries.desktop`
  margin: 50px auto;
  max-width: 100%;
`};
`

const LinesHolder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
const ImgForLine = styled.img`
  position: absolute;
  z-index: ${(p: any) => p.zIndex};
  top: ${(p: any) => p.top};
  left: ${(p: any) => p.left};
  right: ${(p: any) => p.right};

  ${mediaqueries.desktop`
  display: none;
`};
`

const StyledImage = styled.img`
  ${mediaqueries.desktop`
margin: 100px auto 265px
filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.25));
`};
`
