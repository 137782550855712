import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../section"
import SectionTitle from "../../sectionTitle"
import Layout from "../../layout"
import styled from "@emotion/styled"
import Lightbox from "../../lightbox"

import mediaqueries from "../../../styles/media"

const siteQuery = graphql`
  query part3Query {
    mdx(frontmatter: { gatsbyTitle: { eq: "product-part3" } }) {
      id
      body
      frontmatter {
        title
        description
        text1
        text2
        text3
        image {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(
              maxWidth: 1440
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              originalImg
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        image3 {
          id
          publicURL
        }
      }
    }
  }
`
const Part3 = () => {
  const data = useStaticQuery(siteQuery)

  const {
    title,
    description,
    text1,
    text2,
    text3,
    image,
    image2,
    image3,
  } = data.mdx.frontmatter

  const { body } = data.mdx

  return (
    <BodyHolder>
      <Layout>
        <Section>
          <SectionTitle
            number={3}
            text={title}
            description={description}
            marginLeft="122px"
          />
          <SubSection gridString="1fr">
            <Lightbox src={image.childImageSharp.fluid.originalImg}>
              <Img alt={description} fluid={image.childImageSharp.fluid} />
            </Lightbox>
          </SubSection>
          <SubSection gridString="1fr 1fr 2fr">
            <div />
            <BoldHolder maxWidth="320px">{text1}</BoldHolder>
            <Lightbox src={image2.childImageSharp.fluid.originalImg}>
              <Img alt={text2} fluid={image2.childImageSharp.fluid} />
            </Lightbox>
          </SubSection>
          <SubSection gridString="1fr 3fr 2fr">
            <div />
            <ShadowBox zIndex="2" marginRight="80px">
              <img src={image3.publicURL} alt="OpalCBox" />
            </ShadowBox>
            <BoldHolder maxWidth="330px">{text2}</BoldHolder>
          </SubSection>
          <SpaceMaker />
          <SubSection gridString="1fr 5fr">
            <div />
            <BoldHolder>{text3}</BoldHolder>
          </SubSection>
        </Section>
      </Layout>
    </BodyHolder>
  )
}

export default Part3

const BodyHolder = styled.section`
  background: ${(p: any) => p.theme.colors.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
`
const SubSection = styled.div`
  display: grid;
  grid-template-columns: ${(p: any) => p.gridString};
  grid-column-gap: 40px;
  margin-top: 100px;

  ${mediaqueries.desktop`
  grid-template-columns: 1fr;
  margin-top: 50px;
`};
`
const BoldHolder = styled.div`
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 197.18%;
  /* or 35px */
  letter-spacing: 0.015em;
  max-width: ${(p: any) => p.maxWidth};

  ${mediaqueries.desktop`
  margin: 50px auto;
  max-width: 100%;
`};
`

const ShadowBox = styled.div`
  margin-top: ${p => p.marginTop};
  margin-left: ${p => p.marginLeft};
  border-radius: ${p => p.theme.radius.normal};
  filter: ${(p: any) => p.theme.shadow.box};
  overflow: hidden;
  z-index: ${(p: any) => p.zIndex};

  ${mediaqueries.desktop`
  margin: 50px auto;
  max-width: 100%;
`};
`
const SpaceMaker = styled.div`
  height: 50px;
  width: 100%;

  ${mediaqueries.desktop`
  display: none;
`};
`
