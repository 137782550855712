import React from "react"
import styled from "@emotion/styled"

import mediaqueries from "../../styles/media"

interface Props {
  text: string
  justifySelf?: string
}

const OrangeBox: React.FC<Props> = ({ text, justifySelf = "center" }) => (
  <Box justifySelf={justifySelf}>
    <Text>{text}</Text>
  </Box>
)

export default OrangeBox

const Box = styled.div`
  background: ${(p: any) => p.theme.colors.card};
  border-radius: ${(p: any) => p.theme.radius.normal};
  padding: 25px 60px;
  display: flex;
  min-height: 250px;
  max-width: 390px;
  margin-right: 40px;
  justify-content: center;
  color: ${(p: any) => p.theme.colors.background};
  justify-self: ${(p: any) => p.justifySelf};

  ${mediaqueries.desktop`
  flex-direction: column;
  padding: 25px 30px;
  margin: 25px auto;
`};
`

const Text = styled.h4`
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 197.18%;
  /* or 37px */
  letter-spacing: 0.015em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  ${mediaqueries.desktop`
    font-size: 16px;
`};
`
